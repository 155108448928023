const getSubscriberCount = async ({
  pageId,
  segmentId,
}: {
  pageId: string;
  segmentId: string;
}) => {
  const response = await fetch(
    `/api/segment/count/${pageId}?segmentId=${segmentId}`
  );
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const getSegments = async ({
  pageId,
  page,
  PAGE_SIZE,
}: {
  pageId: string;
  page: number;
  PAGE_SIZE: number;
}) => {
  const response = await fetch(
    `/api/segment/page/${pageId}?page=${page}&limit=${PAGE_SIZE}&sort=-createDate`
  );
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const getSegment = async ({
  pageId,
  segmentId,
}: {
  pageId: string;
  segmentId: string;
}) => {
  const response = await fetch(
    `/api/segment/page/${pageId}?segmentId=${segmentId}`
  );
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const createSegment = async ({
  pageId,
  segmentName,
  contactCount,
  isList,
  queryString,
}: {
  pageId: string;
  segmentName: string;
  contactCount: number;
  isList: boolean;
  queryString: string[][] | [] | undefined;
}) => {
  const response = await fetch(`/api/segment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      pageId,
      segmentName,
      contactCount,
      isList,
      queryString,
    }),
  });
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const updateSegment = async ({
  segmentId,
  segmentName,
  contactCount,
  isList,
  queryString,
}: {
  segmentId: string;
  segmentName: string;
  contactCount: number;
  isList: boolean;
  queryString: [];
}) => {
  const response = await fetch(`/api/segment/${segmentId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      segmentName,
      contactCount,
      isList,
      queryString,
    }),
  });
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const reviewSegment = async ({
  pageId,
  queryString,
}: {
  pageId: string;
  queryString: [];
}) => {
  const response = await fetch(`/api/segment/review`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ pageId, queryString }),
  });
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

export {
  getSubscriberCount,
  getSegments,
  getSegment,
  createSegment,
  updateSegment,
  reviewSegment,
};
