import { useContext } from "react";
import { Outlet } from "react-router-dom";

import Box from "@mui/material/Box";

import { useBoolean } from "../../hooks/";

import Main from "./Main";
import Header from "./Header";
import NavVertical from "./Nav-vertical";

import PageContext from "../../context/pageContext";
import { IPageContext } from "../../types/page.type";

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { pageIsLoading } = useContext(PageContext) as IPageContext;
  const nav = useBoolean();

  const renderNavVertical = (
    <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />
  );

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        {renderNavVertical}

        <Main>{!pageIsLoading && <Outlet />}</Main>
      </Box>
    </>
  );
}
