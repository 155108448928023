import { useState, useEffect, useCallback } from "react";
import { useRouter, useAuthContext } from "../hooks";

import { SplashScreen } from "../components/loading-screen";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { loading } = useAuthContext();
  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const router = useRouter();
  const { authenticated, user } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      const href = `/?${searchParams}`;

      router.replace(href);
    } else if (Object.keys(user?.connectedPages).length === 0) {
      const noPagesConnectedHref = "/connect";

      router.replace(noPagesConnectedHref);
    } else {
      setChecked(true);
    }
  }, [authenticated, router, user]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
