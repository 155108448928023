import {
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";

import Spinner from "../components/Spinner";
import CustomBreadcrumbs from "../components/custom-breadcrumbs";
import { useSnackbar } from "../components/snackbar";
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from "../components/table";
import PageContext from "../context/pageContext";
import { cancelBroadcast, getBroadcasts } from "../helpers/apiBroadcast";
import { useRouter } from "../hooks";
import BroadcastTableRow from "../sections/broadcast/broadcast-table-row";
import { IBroadcast } from "../types/broadcast.type";
import { IPageContext } from "../types/page.type";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "broadcastName", label: "Name" },
  { id: "broadcastStatus", label: "Status" },
  { id: "broadcastTime", label: "Delivery Date" },
  { id: "broadcastRecipientCount", label: "Recipients" },
  { id: "broadcastReadCount24Hour", label: "Read (24hours)" },
  { id: "broadcastReadCount7Day", label: "Read (7days)" },
  { id: "broadcastClickCount", label: "Unique Clicks" },
  { id: "broadcastAttributionRevenue", label: "Revenue" },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function CampaignListView() {
  const { pageId } = useContext(PageContext) as IPageContext;
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const table = useTable({ defaultOrderBy: "broadcastTime" });

  const [tableData, setTableData] = useState<IBroadcast[]>([]);

  const queryClient = useQueryClient();

  let page = table.page + 1;
  let PAGE_SIZE = table.rowsPerPage;

  const { isLoading, data: { data, results: count } = {} } = useQuery({
    queryKey: ["broadcasts", pageId, page],
    queryFn: () => getBroadcasts({ pageId, page, PAGE_SIZE, isFlow: false }),
    retry: 1,
  });

  useEffect(() => {
    if (!isLoading && data?.broadcasts) {
      setTableData(data.broadcasts);
    }
  }, [isLoading, data]);

  if (isLoading) {
    return <Spinner />;
  }

  const pageCount = Math.ceil(count / PAGE_SIZE);

  if (page < pageCount)
    queryClient.prefetchQuery({
      queryKey: ["broadcasts", pageId, page + 1],
      queryFn: () =>
        getBroadcasts({ pageId, page: page + 1, PAGE_SIZE, isFlow: false }),
    });
  if (page > 1)
    queryClient.prefetchQuery({
      queryKey: ["broadcasts", pageId, page - 1],
      queryFn: () =>
        getBroadcasts({ pageId, page: page - 1, PAGE_SIZE, isFlow: false }),
    });

  const handleCancelRow = async (broadcastId: string) => {
    const res = await cancelBroadcast(broadcastId);

    if (res.status === "success") {
      enqueueSnackbar("Broadcast Canceled", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      window.location.reload();
    } else {
      enqueueSnackbar(res.statusText, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };
  const handleViewRow = async (broadcastId: string) => {
    const url = `/app/campaign/${broadcastId}`;
    router.push(url);
  };

  const handleEditRow = async (broadcastId: string) => {
    const url = `/app/campaign/${broadcastId}/edit`;
    router.push(url);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          direction="column"
          alignItems="left"
          sx={{
            mb: { xs: 3, md: 5 },
            padding: "1rem",
          }}
        >
          <CustomBreadcrumbs
            heading="Campaigns"
            links={[
              {
                name: "Home",
                href: "/",
              },
              {
                name: "Campaigns",
              },
            ]}
          />

          <Card>
            <TableContainer
              sx={{ position: "relative" }}
              style={{ marginBottom: "0px" }}
            >
              <Table size={"small"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                />

                <TableBody style={{ marginBottom: "0px" }}>
                  {tableData.length > 0 ? (
                    tableData.map((row) => (
                      <BroadcastTableRow
                        key={row.broadcastId}
                        row={row}
                        onViewRow={() => handleViewRow(row.broadcastId)}
                        onCancelRow={() => handleCancelRow(row.broadcastId)}
                        onEditRow={() => handleEditRow(row.broadcastId)}
                      />
                    ))
                  ) : (
                    <TableNoData notFound={true} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationCustom
              count={count || 0}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={(event, newPage) => {
                table.onChangePage(event, newPage);
              }}
              style={{ marginTop: "0px" }}
            />
          </Card>
        </Stack>
      </Container>
    </>
  );
}
