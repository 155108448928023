import { useContext, useState, useEffect } from "react";

import {
  Stack,
  Card,
  Table,
  Container,
  TableBody,
  TableContainer,
} from "@mui/material";

import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from "../components/table";

import CustomBreadcrumbs from "../components/custom-breadcrumbs";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import PageContext from "../context/pageContext";

import { getContacts, changeSubscription } from "../helpers/apiContact";

import Spinner from "../components/Spinner";
import { useRouter } from "../hooks";
import { ISubscriber } from "../types/subscriber.type";
import { IPageContext } from "../types/page.type";
import SubscriberTableRow from "../sections/subscriber/subscriber-table-row";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "contactFullName", label: "Name" },
  { id: "contactGender", label: "Gender" },
  { id: "lastInteraction", label: "Last Interaction" },
  { id: "subscriptionStatus", label: "Status" },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function SubscriberListView() {
  const { pageId } = useContext(PageContext) as IPageContext;
  const router = useRouter();

  const table = useTable({ defaultOrderBy: "lastInteraction" });

  const [tableData, setTableData] = useState<ISubscriber[]>([]);

  const queryClient = useQueryClient();

  let page = table.page + 1;
  let PAGE_SIZE = table.rowsPerPage;
  let orderBy = "-lastInteraction";

  const { isLoading, data: { data, results: count } = {} } = useQuery({
    queryKey: ["contacts", pageId, page],
    queryFn: () => getContacts({ pageId, page, PAGE_SIZE, sortField: orderBy }),
    retry: 1,
  });

  useEffect(() => {
    if (!isLoading && data?.contacts) {
      setTableData(data.contacts);
    }
  }, [isLoading, data]);

  if (isLoading) {
    return <Spinner />;
  }

  const pageCount = Math.ceil(count / PAGE_SIZE);

  if (page < pageCount)
    queryClient.prefetchQuery({
      queryKey: ["contacts", pageId, page + 1],
      queryFn: () =>
        getContacts({ pageId, page: page + 1, PAGE_SIZE, sortField: orderBy }),
    });
  if (page > 1)
    queryClient.prefetchQuery({
      queryKey: ["contacts", pageId, page - 1],
      queryFn: () =>
        getContacts({ pageId, page: page - 1, PAGE_SIZE, sortField: orderBy }),
    });

  const handleToggle = async (
    status: string,
    pageId: string,
    contactId: string
  ) => {
    const newStatus = status === "1" ? "3" : "1";
    const updatedTableData = tableData.map((row) => {
      if (row.contactId === contactId) {
        return { ...row, subscriptionStatus: newStatus };
      }
      return row;
    });
    setTableData(updatedTableData);

    await changeSubscription({
      pageId: pageId,
      contactId: contactId,
    });
  };

  const handleViewRow = async (contactId: string) => {
    const url = `/app/subscribers/${contactId}`;
    router.push(url);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          direction="column"
          alignItems="left"
          sx={{
            mb: { xs: 3, md: 5 },
            padding: "1rem",
          }}
        >
          <CustomBreadcrumbs
            heading="Subscribers"
            links={[
              {
                name: "Home",
                href: "/",
              },
              {
                name: "Subscribers",
              },
            ]}
          />

          <Card>
            <TableContainer
              sx={{ position: "relative" }}
              style={{ marginBottom: "0px" }}
            >
              <Table size={"small"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  // onSort={table.onSort}
                />

                <TableBody style={{ marginBottom: "0px" }}>
                  {tableData.length > 0 ? (
                    tableData.map((row) => (
                      <SubscriberTableRow
                        key={row.contactId}
                        row={row}
                        onViewRow={() => handleViewRow(row.contactId)}
                        onToggle={() =>
                          handleToggle(
                            row.subscriptionStatus,
                            row.pageId,
                            row.contactId
                          )
                        }
                      />
                    ))
                  ) : (
                    <TableNoData notFound={true} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationCustom
              count={count || 0}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={(event, newPage) => {
                table.onChangePage(event, newPage);
              }}
              style={{ marginTop: "0px" }}
            />
          </Card>
        </Stack>
      </Container>
    </>
  );
}
