import { createContext, useState } from "react";

import { ICampaignFormContext } from "../types/broadcast.type";

const CampaignFormContext = createContext({} as ICampaignFormContext);

export const CampaignFormProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [campaignFormDetails, setCampaignFormDetails] = useState({});

  return (
    <CampaignFormContext.Provider
      value={{
        campaignFormDetails,
        setCampaignFormDetails,
      }}
    >
      {children}
    </CampaignFormContext.Provider>
  );
};

export default CampaignFormContext;
