import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import AuthGuard from "./auth-guard/AuthGuard";
import GuestGuard from "./auth-guard/GuestGuard";
import SnackbarProvider from "./components/snackbar/snackbar-provider";
import { AuthProvider } from "./context/authContext";
import { CampaignFormProvider } from "./context/campaignFormContext";
import LocalizationProvider from "./context/localizationProvider";
import { PageProvider } from "./context/pageContext";
import "./global.css";
import FacebookSDKInitializer from "./helpers/FacebookSDKInitializer";
import Auth from "./layouts/auth";
import DashboardLayout from "./layouts/dashboard";
import ActivatePage from "./pages/ActivatePage";
import CampaignConfirm from "./pages/CampaignConfirm";
import CampaignCreate from "./pages/CampaignCreate";
import CampaignDetail from "./pages/CampaignDetail";
import CampaignEdit from "./pages/CampaignEdit";
import CampaignList from "./pages/CampaignList";
import ConnectPage from "./pages/ConnectPage";
import ContactNew from "./pages/ContactNew";
import ForgotPassword from "./pages/ForgotPassword";
import Growth from "./pages/Growth";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import SegmentCreateNew from "./pages/SegmentCreateNew";
import SegmentNew from "./pages/SegmentNew";
import Settings from "./pages/Settings";
import SignUp from "./pages/SignUp";
import SignUpShopify from "./pages/SignUpShopify";
import SubscriberTableNew from "./pages/SubscriberTableNew";
import TemplateFormNew from "./pages/TemplateForm";
import Welcome from "./pages/Welcome";
import WelcomeShopify from "./pages/WelcomeShopify";
import ThemeProvider from "./theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <FacebookSDKInitializer />
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider>
        <LocalizationProvider>
          <ThemeProvider>
            <SnackbarProvider>
              <CampaignFormProvider>
                <PageProvider>
                  <BrowserRouter>
                    <Routes>
                      <Route
                        element={
                          <GuestGuard>
                            <Auth />
                          </GuestGuard>
                        }
                      >
                        <Route path="/" element={<Login />} />
                        <Route path="signup" element={<SignUp />} />
                        <Route
                          path="forgot-password"
                          element={<ForgotPassword />}
                        />
                        <Route
                          path="reset-password/:token"
                          element={<ResetPassword />}
                        />
                        <Route
                          path="shopify-signup"
                          element={<SignUpShopify />}
                        />
                        <Route path="connect" element={<ConnectPage />} />
                      </Route>

                      <Route
                        element={
                          <AuthGuard>
                            <Auth />
                          </AuthGuard>
                        }
                      >
                        <Route path="welcome" element={<Welcome />} />
                        <Route
                          path="shopify-welcome"
                          element={<WelcomeShopify />}
                        />
                      </Route>

                      <Route
                        path="app"
                        element={
                          <AuthGuard>
                            <DashboardLayout />
                          </AuthGuard>
                        }
                      >
                        <Route index element={<Navigate replace to="home" />} />
                        <Route path="home" element={<CampaignCreate />} />

                        <Route path="subscribers">
                          <Route index element={<SubscriberTableNew />} />
                          <Route path=":contactId" element={<ContactNew />} />
                        </Route>

                        <Route path="campaign">
                          <Route index element={<CampaignList />} />
                          <Route path="confirm" element={<CampaignConfirm />} />
                          <Route path=":broadcastId">
                            <Route
                              index
                              element={<Navigate replace to="overview" />}
                            />
                            <Route
                              path="overview"
                              element={<CampaignDetail />}
                            />
                            <Route
                              path="recipient"
                              element={<CampaignDetail />}
                            />
                            <Route path="edit" element={<CampaignEdit />} />
                          </Route>
                        </Route>

                        <Route path="growth" element={<Growth />} />

                        <Route path="settings">
                          <Route
                            index
                            element={<Navigate replace to="account" />}
                          />
                          <Route path="account" element={<Settings />} />
                          <Route path="billing" element={<Settings />} />
                          <Route path="integration" element={<Settings />} />
                        </Route>

                        <Route path="segment">
                          <Route index element={<SegmentNew />} />
                          <Route path="create" element={<SegmentCreateNew />} />
                        </Route>

                        <Route path="template" element={<TemplateFormNew />} />

                        <Route path="activation" element={<ActivatePage />} />
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </PageProvider>
              </CampaignFormProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
