import { useContext } from "react";

import { Card, CardHeader, Stack, Typography } from "@mui/material";

import Iconify from "../../components/iconify";

import PageContext from "../../context/pageContext";
import { IPageContext } from "../../types/page.type";

// ----------------------------------------------------------------------

export default function SettingsIntegration() {
  const { fbPage } = useContext(PageContext) as IPageContext;

  const renderShopifyApiKey = (
    <>
      <Stack direction="row" spacing={1}>
        <Iconify icon="material-symbols:store" width={24} />
        <Typography variant="body2">{fbPage.shopifyId}</Typography>
      </Stack>
    </>
  );

  return (
    <>
      <Card>
        <CardHeader title="Shopify Connection Details" />
        <Stack spacing={2.5} sx={{ p: 3 }}>
          {renderShopifyApiKey}
        </Stack>
      </Card>
    </>
  );
}
