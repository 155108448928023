import { useContext, useState, useEffect } from "react";

import {
  Stack,
  Card,
  Table,
  Container,
  TableBody,
  TableContainer,
  Button,
} from "@mui/material";

import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from "../components/table";

import CustomBreadcrumbs from "../components/custom-breadcrumbs";
import Iconify from "../components/iconify";

import { ISegment } from "../types/segment.type";

import SegmentTableRow from "../sections/segment/segment-table-row";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import PageContext from "../context/pageContext";

import { getSegments } from "../helpers/apiSegment";
import { RouterLink } from "../helpers/router-link";

import Spinner from "../components/Spinner";

import { IPageContext } from "../types/page.type";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "segmentName", label: "Name" },
  { id: "isList", label: "Type" },
  { id: "contactCount", label: "Members" },
  { id: "createDate", label: "Created" },
];

// ----------------------------------------------------------------------

export default function SegmentListView() {
  const { pageId } = useContext(PageContext) as IPageContext;

  const table = useTable();

  const [tableData, setTableData] = useState<ISegment[]>([]);

  const queryClient = useQueryClient();

  let page = table.page + 1;
  let PAGE_SIZE = table.rowsPerPage;

  const { isLoading, data: { data, results: count } = {} } = useQuery({
    queryKey: ["segments", pageId, page],
    queryFn: () => getSegments({ pageId, page, PAGE_SIZE }),
    retry: 1,
  });

  useEffect(() => {
    if (!isLoading && data?.segments) {
      setTableData(data.segments);
    }
  }, [isLoading, data]);

  if (isLoading) {
    return <Spinner />;
  }

  const pageCount = Math.ceil(count / PAGE_SIZE);

  if (page < pageCount)
    queryClient.prefetchQuery({
      queryKey: ["segments", pageId, page + 1],
      queryFn: () => getSegments({ pageId, page: page + 1, PAGE_SIZE }),
    });

  if (page > 1)
    queryClient.prefetchQuery({
      queryKey: ["segments", pageId, page - 1],
      queryFn: () => getSegments({ pageId, page: page - 1, PAGE_SIZE }),
    });

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          direction="column"
          alignItems="left"
          sx={{
            mb: { xs: 3, md: 5 },
            padding: "1rem",
          }}
        >
          <CustomBreadcrumbs
            heading="Segments"
            links={[
              {
                name: "Home",
                href: "/",
              },
              {
                name: "Segments",
              },
            ]}
            action={
              <Button
                component={RouterLink}
                href="/app/segment/create"
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                New Segment
              </Button>
            }
          />
          <Card>
            <TableContainer
              sx={{ position: "relative" }}
              style={{ marginBottom: "0px" }}
            >
              <Table size={"small"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                />

                <TableBody style={{ marginBottom: "0px" }}>
                  {tableData.length > 0 ? (
                    tableData.map((row) => (
                      <SegmentTableRow key={row.segmentId} row={row} />
                    ))
                  ) : (
                    <TableNoData notFound={true} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePaginationCustom
              count={count || 0}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={(event, newPage) => {
                table.onChangePage(event, newPage);
              }}
              style={{ marginTop: "0px" }}
            />
          </Card>
        </Stack>
      </Container>
    </>
  );
}
