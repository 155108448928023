const getBroadcasts = async ({
  pageId,
  page,
  PAGE_SIZE,
  isFlow,
}: {
  pageId: string;
  page: number;
  PAGE_SIZE: number;
  isFlow: boolean;
}) => {
  const response = await fetch(
    `/api/broadcast/${pageId}?page=${page}&limit=${PAGE_SIZE}&sort=-broadcastTime&isFlow=${isFlow}`
  );
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const getBroadcast = async ({
  pageId,
  broadcastId,
}: {
  pageId: string;
  broadcastId: string;
}) => {
  const response = await fetch(
    `/api/broadcast/${pageId}?broadcastId=${broadcastId}`
  );
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const cancelBroadcast = async (broadcastId: string) => {
  const response = await fetch(`/api/broadcast/${broadcastId}`, {
    method: "DELETE",
  });
  const data = await response.json();
  return data;
};

const getMessageStatsForBroadcast = async ({
  broadcastId,
}: {
  broadcastId: string;
}) => {
  const response = await fetch(`/api/v2/broadcast/${broadcastId}/messageStats`);
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};
export {
  getBroadcasts,
  getBroadcast,
  cancelBroadcast,
  getMessageStatsForBroadcast,
};
