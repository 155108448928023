import { Container, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { useContext } from "react";

import CustomBreadcrumbs from "../components/custom-breadcrumbs";
import { isProd } from "../config";
import CampaignFormContext from "../context/campaignFormContext";
import { useRouter } from "../hooks";
import { IBroadcast } from "../types/broadcast.type";

// ----------------------------------------------------------------------

export default function CampaignConfirm() {
  const router = useRouter();
  const { campaignFormDetails } = useContext(CampaignFormContext);

  const broadcastDetails = campaignFormDetails as IBroadcast;

  return (
    <Container maxWidth="lg">
      <Stack
        spacing={2}
        direction="column"
        alignItems="left"
        sx={{
          mb: { xs: 3, md: 5 },
          padding: "1rem",
        }}
      >
        <CustomBreadcrumbs
          heading="Campaigns"
          links={[
            {
              name: "Home",
              href: "/app/home",
            },
            {
              name: "Campaigns",
              href: "/app/campaign",
            },
            {
              name: broadcastDetails.broadcastName,
            },
          ]}
        />
        <Card>
          <CardHeader title="Your campaign is waiting to go" />
          <Stack spacing={3} sx={{ p: 3 }}>
            <Typography variant="body1">
              <strong>{broadcastDetails.broadcastName}</strong> is queued up and
              waiting to be sent.
            </Typography>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => router.push("/app/campaign")}
              sx={{
                p: 1,
                width: "145px",
              }}
            >
              Go to campaigns
            </Button>
          </Stack>
        </Card>
        {!isProd && (
          <Card>
            <CardHeader title="DEV ONLY - DEBUG SENT FORM" />
            <Stack spacing={3} sx={{ p: 3 }}>
              {Object.entries(broadcastDetails).map(([key, value]) => (
                <Typography variant="body1" key={key}>
                  {key}: {String(value)}
                </Typography>
              ))}
            </Stack>
          </Card>
        )}
      </Stack>
    </Container>
  );
}
