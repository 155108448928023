import { TableRow, TableCell, Chip } from "@mui/material";

import { ISegment } from "../../types/segment.type";

// ----------------------------------------------------------------------

type Props = {
  row: ISegment;
};

export default function SegmentTableRow({ row }: Props) {
  const { segmentName, isList, createDate, contactCount } = row;

  return (
    <>
      <TableRow hover sx={{ fontWeight: 300 }}>
        <TableCell sx={{ width: 350 }}>{segmentName}</TableCell>

        <TableCell>
          <Chip
            label={isList ? "List" : "Segment"}
            variant="outlined"
            size="small"
          />
        </TableCell>

        <TableCell>{contactCount}</TableCell>

        <TableCell>{new Date(createDate).toLocaleDateString()}</TableCell>
      </TableRow>
    </>
  );
}
