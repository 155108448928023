import { Card, CardActionArea, Typography } from "@mui/material";

type Props = {
  message: String;
  onClick: VoidFunction;
  color: string;
};

export default function Banner({ message, color, onClick }: Props) {
  return (
    <Card sx={{ minHeight: 40, borderRadius: 0, backgroundColor: color }}>
      <CardActionArea
        onClick={onClick}
        sx={{ display: "flex", alignItems: "center", height: "100%" }}
      >
        <Typography variant="overline" align="center">
          {message}
        </Typography>
      </CardActionArea>
    </Card>
  );
}
