import apiClient from "./client";

const sendBroadcast = async (formData: object) => {
  const response = await apiClient.post("/api/broadcast", formData);
  return response.data;
};

const sendPreview = async (formData: object) => {
  const response = await apiClient.post("/api/message/preview", formData);
  return response.data;
};

const updateBroadcast = async ({
  broadcastId,
  formData,
}: {
  broadcastId: string;
  formData: any;
}) => {
  const response = await apiClient.put(
    `/api/broadcast/${broadcastId}`,
    formData
  );
  return response.data;
};

export { sendBroadcast, sendPreview, updateBroadcast };
