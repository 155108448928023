import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material/styles";
import { AnimatePresence, m } from "framer-motion";

import { varFade } from "./animate";
import { fileThumb } from "./file-thumbnail/utils";
import Iconify from "./iconify";

// ----------------------------------------------------------------------

export default function FilePreview({ file, onRemove, sx }: any) {
  const { fileLink, fileType } = file;

  return (
    <AnimatePresence initial={false}>
      <Stack
        component={m.div}
        {...varFade().inUp}
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{
          my: 1,
          py: 1,
          px: 1.5,
          borderRadius: 1,
          border: (theme) =>
            `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
          ...sx,
        }}
      >
        <Box
          component="img"
          src={fileThumb(fileType)}
          sx={{
            width: 32,
            height: 32,
            flexShrink: 0,
            ...sx,
          }}
        />

        <ListItemText
          primary={
            <Link
              href={fileLink}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "black" }}
            >
              {fileType.toUpperCase() + " file uploaded"}
            </Link>
          }
          // primary={fileName}
          // secondary={fileType.toUpperCase()}
          // secondaryTypographyProps={{
          //   component: "span",
          //   typography: "caption",
          // }}
        />

        <IconButton size="small" onClick={onRemove}>
          <Iconify icon="mingcute:close-line" width={16} />
        </IconButton>
      </Stack>
    </AnimatePresence>
  );
}
