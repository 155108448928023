import { useQuery } from "@tanstack/react-query";

import { getPage } from "../apiPage";

const useGetPage = ({ pageId }: { pageId: string }) => {
  const {
    isLoading: pageDetailsIsLoading,
    data: { data: pageData } = {},
    error,
  } = useQuery({
    queryKey: ["page", pageId],
    queryFn: () => getPage(pageId),
    retry: false,
  });

  return { pageDetailsIsLoading, pageData, error };
};

export default useGetPage;
