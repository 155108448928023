import { useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";

import { RouterLink } from "../helpers/router-link";
import {
  useRouter,
  useBoolean,
  useSearchParams,
  useAuthContext,
} from "../hooks";

import Iconify from "../components/iconify";
import FormProvider, { RHFTextField } from "../components/hook-form";

import { AuthFlow } from "../types/auth.type";
// ----------------------------------------------------------------------

export default function Login() {
  const { login, authFlow } = useAuthContext();

  const router = useRouter();
  const password = useBoolean();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get("returnTo");

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { email, password } = data;

      await login?.(email, password);

      const defaultPage = localStorage.getItem("defaultPage");

      if (defaultPage === null) {
        if (authFlow === AuthFlow.SHOPIFY) {
          router.push("/shopify-welcome");
        } else {
          router.push("/welcome");
        }
      } else {
        router.push(returnTo || "/app/home");
      }
    } catch (error: unknown) {
      if (typeof error === "object" && error !== null) {
        const err = error as { message?: string };
        if (err.message === "Incorrect email or password") {
          setErrorMessage("Incorrect email or password. Please try again.");
        } else {
          setErrorMessage("An error occurred. Please try again.");
        }
      }
    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Welcome Back</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2">Don't have an account?</Typography>

        <Link component={RouterLink} href="/signup" variant="subtitle2">
          Create an account
        </Link>
      </Stack>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      <RHFTextField name="email" label="Email address" />

      <RHFTextField
        name="password"
        label="Password (min. 8 characters)"
        type={password.value ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify
                  icon={
                    password.value ? "solar:eye-bold" : "solar:eye-closed-bold"
                  }
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Link
        component={RouterLink}
        // href={paths.authDemo.modern.forgotPassword}
        //href="/auth/forgot-password"
        href="/forgot-password"
        variant="body2"
        color="inherit"
        underline="always"
        sx={{ alignSelf: "flex-end" }}
      >
        Forgot password?
      </Link>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        sx={{ justifyContent: "space-between", pl: 2, pr: 1.5 }}
      >
        Login
      </LoadingButton>

      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    </Stack>
  );

  return (
    <>
      {renderHead}

      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>
      <DevTool control={control} />
    </>
  );
}
