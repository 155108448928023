import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";

import CampaignFormContext from "../../context/campaignFormContext";
import { useRouter } from "../../hooks";
import { sendBroadcast } from "../apiBroadcast";

const useSendCampaign = () => {
  const router = useRouter();
  const { setCampaignFormDetails } = useContext(CampaignFormContext);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: sendBroadcast,
    onSuccess: (data, variables) => {
      setCampaignFormDetails(variables);
      queryClient.invalidateQueries({ queryKey: ["broadcasts"] });
      router.push("/app/campaign/confirm");
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

export default useSendCampaign;
