import { useFieldArray, useFormContext } from "react-hook-form";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";

import Iconify from "../../components/iconify";
import { RHFSelect, RHFTextField } from "../../components/hook-form";

// ----------------------------------------------------------------------

const SUBSCRIBER_ACTION_OPTIONS = [
  {
    id: "1",
    value: "lastInteraction",
    name: "Message read",
  },
  {
    id: "2",
    value: "contactFirstSubscribeDate",
    name: "Date subscribed",
  },
  {
    id: "3",
    value: "lastMessageSentDate",
    name: "Most recent message sent",
  },
];

const SUBSCRIBER_TIMEFRAME_OPTIONS = [
  {
    id: "1",
    value: "gte",
    name: "is within the last",
  },
  {
    id: "2",
    value: "lt",
    name: "is not within the last",
  },
];

export default function SegmentDefinition() {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const handleAdd = () => {
    append({
      subscriberAction: "",
      subscriberTimeframe: "",
      subscriberTime: 0,
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="subtitle2" sx={{ mb: 3 }}>
        Definition
      </Typography>

      <Stack
        divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
        spacing={3}
      >
        {fields.map((item, index) => (
          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              sx={{ width: 1 }}
            >
              <RHFSelect
                name={`items[${index}].subscriberAction`}
                size="small"
                sx={{
                  maxWidth: { md: 240 },
                }}
              >
                {SUBSCRIBER_ACTION_OPTIONS.map((action) => (
                  <MenuItem key={action.id} value={action.value}>
                    {action.name}
                  </MenuItem>
                ))}
              </RHFSelect>

              <RHFSelect
                name={`items[${index}].subscriberTimeframe`}
                size="small"
                sx={{
                  maxWidth: { md: 240 },
                }}
              >
                {SUBSCRIBER_TIMEFRAME_OPTIONS.map((timeFrame) => (
                  <MenuItem key={timeFrame.id} value={timeFrame.value}>
                    {timeFrame.name}
                  </MenuItem>
                ))}
              </RHFSelect>

              <RHFTextField
                size="small"
                type="number"
                name={`items[${index}].subscriberTime`}
                placeholder="0"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        sx={{ typography: "subtitle2", color: "text.disabled" }}
                      >
                        days
                      </Box>
                    </InputAdornment>
                  ),
                }}
                sx={{ maxWidth: { md: 96 } }}
              />
            </Stack>
            {index !== 0 && (
              <Button
                size="small"
                color="error"
                startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                onClick={() => handleRemove(index)}
              >
                Remove
              </Button>
            )}
          </Stack>
        ))}
      </Stack>

      <Divider sx={{ my: 3, borderStyle: "dashed" }} />

      <Stack
        spacing={3}
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "flex-end", md: "center" }}
      >
        <Button
          size="small"
          color="primary"
          startIcon={<Iconify icon="mingcute:add-line" />}
          onClick={handleAdd}
          sx={{ flexShrink: 0 }}
        >
          Add Item
        </Button>
      </Stack>
    </Box>
  );
}
