import { useMemo } from "react";

import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <Iconify icon={`material-symbols:${name}`} width={24} />
  // https://icon-sets.iconify.design/material-symbols
);

const ICONS = {
  home: icon("home"),
  growth: icon("bolt"),
  subscribers: icon("emoji-people"),
  segment: icon("data-table"),
  campaign: icon("send-rounded"),
  settings: icon("settings-rounded"),
  placeholder: icon("home-outline-rounded"),
  placeholder_2: icon("home-outline-rounded"),
};
//first create the sidebar to match the production version and add logos
//remember flexboxes and CSS for later

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: "overview",
        items: [
          {
            title: "Home",
            path: "/app/home",
            icon: ICONS.home,
          },
          {
            title: "Growth",
            path: "/app/growth",
            icon: ICONS.growth,
          },
          {
            title: "Subscribers",
            path: "/app/subscribers",
            icon: ICONS.subscribers,
          },
          {
            title: "Segments",
            path: "/app/segment",
            icon: ICONS.segment,
          },
          {
            title: "Campaigns",
            path: "/app/campaign",
            icon: ICONS.campaign,
          },
          {
            title: "Settings",
            path: "/app/settings",
            icon: ICONS.settings,
          },
        ],
      },
    ],
    []
  );

  return data;
}
