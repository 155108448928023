import {
  Card,
  Button,
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";

import { savePage, loginWithFacebook } from "../../helpers/fbServices";

import { useAuthContext } from "../../hooks";

import Iconify from "../../components/iconify";
import { useTable, TableHeadCustom } from "../../components/table";

import SettingsApiTableRow from "./settings-api-table-row";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "apiKeyName", label: "Name" },
  { id: "apiKey", label: "Key" },
  { id: "", label: "Action" },
];

export default function SettingsAccount() {
  const { connectPage } = useAuthContext();

  const table = useTable();

  const tableData: { apiKeyName: string; apiKey: string }[] = [
    {
      apiKeyName: "Test",
      apiKey: "123456789",
    },
  ];

  const performConnect = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    try {
      const userAuthResponse = await loginWithFacebook();
      const userId = localStorage.getItem("userId") || "";

      const { connectedPages } = await savePage({ userAuthResponse, userId });

      connectPage(connectedPages);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  };

  const renderConnect = (
    <Card>
      <CardHeader title="Connected Pages" />
      <Stack spacing={2.5} sx={{ p: 3 }}>
        <Button
          size="small"
          startIcon={<Iconify icon={"bi:facebook"} />}
          onClick={performConnect}
          sx={(theme) => ({
            p: 2.5,
            border: 1,
            backgroundColor: "#316FF6",
            color: theme.palette.grey[100],
            width: "200px",
            "&:hover": {
              backgroundColor: "#2758c4",
            },
          })}
        >
          Manage Pages
        </Button>
      </Stack>
    </Card>
  );
  // eslint-disable-next-line
  const renderApi = (
    <Card>
      <CardHeader
        title="API Keys"
        action={
          <Button
            size="small"
            color="primary"
            startIcon={<Iconify icon="mingcute:add-line" />}
            // onClick={addressForm.onTrue}
          >
            API Key
          </Button>
        }
      />
      <Stack spacing={2.5} sx={{ p: 3 }}>
        {tableData.length > 0 && (
          <TableContainer sx={{ position: "relative" }}>
            <Table size={"small"}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
              />

              <TableBody>
                {tableData.map((row) => (
                  <SettingsApiTableRow key={row.apiKey} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Card>
  );

  return (
    <>
      {renderConnect}
      {/* {renderApi} */}
    </>
  );
}
