import { Container, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import Spinner from "../components/Spinner";
import CustomBreadcrumbs from "../components/custom-breadcrumbs";
import PageContext from "../context/pageContext";
import { getBroadcast } from "../helpers/apiBroadcast";
import CampaignCreateEditForm from "../sections/broadcast/campaign-create-edit-form";
import { IBroadcast } from "../types/broadcast.type";
import { IPageContext } from "../types/page.type";

// ----------------------------------------------------------------------

export default function CampaignEdit() {
  const { pageId } = useContext(PageContext) as IPageContext;

  const params = useParams();
  const broadcastId = params.broadcastId ?? "";

  const { isLoading, data } = useQuery({
    queryKey: ["broadcast", pageId, broadcastId],
    queryFn: () => getBroadcast({ pageId, broadcastId }),
    retry: 1,
  });

  const currentBroadcast = data?.data?.broadcasts[0];

  if (isLoading) {
    return <Spinner />;
  }

  const broadcast: IBroadcast = {
    broadcastId: broadcastId,
    broadcastStatus: currentBroadcast.broadcastStatus,
    broadcastName: currentBroadcast.broadcastName,
    broadcastTime: currentBroadcast.broadcastTime,
    broadcastRecipientCount: currentBroadcast.broadcastRecipientCount,
    broadcastReadCount24Hour: currentBroadcast.broadcastReadCount24Hour,
    broadcastReadCount7Day: currentBroadcast.broadcastReadCount7Day,
    broadcastClickCount: currentBroadcast.broadcastClickCount,
    broadcastAttributionRevenue: currentBroadcast.broadcastAttributionRevenue,
    pageId: pageId,
    broadcastText: currentBroadcast.broadcastText,
    fileType: currentBroadcast.fileType,
    fileLink: currentBroadcast.fileLink,
    buttonPrompt: currentBroadcast.buttonPrompt,
    buttonUrl: currentBroadcast.buttonUrl,
    segmentId: currentBroadcast.segmentId,
  };

  return (
    <Container maxWidth="lg">
      <Stack
        spacing={2}
        direction="column"
        alignItems="flex-start"
        sx={{
          mb: { xs: 3, md: 5 },
          padding: "1rem",
        }}
      >
        <CustomBreadcrumbs
          heading="Edit Campaign"
          links={[
            {
              name: "Home",
              href: "/app/home",
            },
            {
              name: "Campaigns",
              href: "/app/campaign",
            },
            {
              name: currentBroadcast.broadcastName,
            },
          ]}
        />
        <CampaignCreateEditForm currentBroadcast={broadcast} />
      </Stack>
    </Container>
  );
}
