import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Stack, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useContext } from "react";
import { useRouter, useAuthContext } from "../hooks";

import Iconify from "../components/iconify";
import FormProvider, { RHFTextField } from "../components/hook-form";

import { IPageContext } from "../types/page.type";
import PageContext from "../context/pageContext";

export default function Welcome() {
  const router = useRouter();
  const { user } = useAuthContext();

  const { setPageId } = useContext(PageContext) as IPageContext;

  const FormSchema = Yup.object().shape({
    pageId: Yup.string().required("Page is required"),
  });

  const defaultValues = {
    pageId: "",
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handlePageChange = handleSubmit(async (data) => {
    try {
      localStorage.setItem("defaultPage", data.pageId);
      setPageId(data.pageId);

      router.push("/app/home");
    } catch (error) {}
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Welcome to 1send!</Typography>
      <Typography variant="body1">
        Select the Page you want to work on.
      </Typography>
    </Stack>
  );

  const renderBody = (
    <Stack spacing={2}>
      <RHFTextField
        name="pageId"
        select
        fullWidth
        SelectProps={{ native: true }}
      >
        <option value="" disabled>
          Select Page
        </option>
        {Object.entries(user?.connectedPages).map(([pageId, pageName]) => (
          <option key={pageId} value={pageId}>
            {pageName as String}
          </option>
        ))}
      </RHFTextField>
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        sx={{ justifyContent: "space-between", pl: 2, pr: 1.5 }}
      >
        Select Page
      </LoadingButton>
    </Stack>
  );

  return (
    <>
      {renderHead}
      <FormProvider methods={methods} onSubmit={handlePageChange}>
        {renderBody}
      </FormProvider>
    </>
  );
}
