import { IconButton, TableRow, TableCell } from "@mui/material";

import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

type Props = {
  row: {
    apiKeyName: string;
    apiKey: string;
  };
};

export default function SettingsApiTableRow({ row }: Props) {
  const { apiKeyName, apiKey } = row;

  const handleCopy = () => {
    navigator.clipboard.writeText(apiKey);
  };

  const handleDelete = () => {
    console.log("delete");
  };

  return (
    <>
      <TableRow hover sx={{ fontWeight: 300 }}>
        <TableCell>{apiKeyName}</TableCell>

        <TableCell>{apiKey}</TableCell>

        <TableCell>
          <IconButton onClick={handleCopy}>
            <Iconify icon="material-symbols:copy-all-outline" />
          </IconButton>
          <IconButton onClick={handleDelete}>
            <Iconify icon="mdi:trash-outline" />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}
