import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Card,
  Button,
  CardHeader,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import {
  createCheckoutSession,
  createCustomerPortalSession,
  getTransaction,
  getShopifyManagedBillingUrl,
} from "../../apis/apiTransaction";

import PageContext from "../../context/pageContext";
import { IPageContext } from "../../types/page.type";

import { fDate } from "../../helpers/format-time";

// ----------------------------------------------------------------------

export default function SettingsBilling() {
  const { fbPage } = useContext(PageContext) as IPageContext;

  const { isLoading, data: transaction } = useQuery({
    queryKey: ["billing", fbPage.pageId],
    queryFn: () => getTransaction({ pageId: fbPage.pageId }),
  });

  const nextPaymentDate = transaction?.currentPeriodEnd
    ? new Date(transaction.currentPeriodEnd)
    : null;

  const formattedNextPaymentDate = fDate(nextPaymentDate, "MMM dd, yyyy");

  const handleBillingAccount = () => {
    if (fbPage?.shopifyId) {
      getShopifyManagedBillingUrl();
      return;
    }

    if (fbPage?.stripeCustomerId) {
      createCustomerPortalSession({ pageId: fbPage.pageId });
    } else {
      createCheckoutSession({ pageId: fbPage.pageId, planId: "1" });
    }
  };

  const renderBillingUsage = (
    <>
      <Grid container alignItems="flex-start">
        <Grid xs={12} sm={4}>
          <Typography variant="subtitle2">{transaction?.name}</Typography>
          <Typography variant="subtitle2">
            {`$${transaction?.price / 100.0} / month`}
          </Typography>
        </Grid>
        <Grid xs={12} sm={4}>
          <Typography variant="subtitle2">Subscribers</Typography>
          <Typography variant="subtitle2">
            {`${fbPage?.pageSubscribers} / ${transaction?.subscriberLimit}`}
          </Typography>
        </Grid>
        <Grid xs={12} sm={4}>
          <Typography variant="subtitle2">Billed Monthly</Typography>
          <Typography variant="subtitle2">
            {formattedNextPaymentDate === ""
              ? "-"
              : `Renews ${formattedNextPaymentDate}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleBillingAccount}
          >
            Manage account
          </Button>
        </Grid>
      </Grid>
    </>
  );
  return (
    <>
      <Card>
        <CardHeader title="Overview" />

        <Stack spacing={2.5} sx={{ p: 3 }}>
          {isLoading ? <CircularProgress /> : renderBillingUsage}
        </Stack>
      </Card>
    </>
  );
}
