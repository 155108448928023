// ----------------------------------------------------------------------

export type IBroadcastTableFilterValue = string | string | Date;

export type IBroadcastTableFilters = {
  broadcastName: string;
  broadcastStatus: string;
  broadcastTime: Date | null;
};

// ----------------------------------------------------------------------

export type IBroadcast = {
  broadcastId: string;
  broadcastStatus: string;
  broadcastName: string;
  broadcastTime: Date;
  broadcastRecipientCount: number;
  broadcastReadCount24Hour: number;
  broadcastReadCount7Day: number;
  broadcastClickCount: number;
  broadcastAttributionRevenue: number;
  pageId: string;
  broadcastText: string;
  fileType: string;
  fileLink: string;
  buttonPrompt: string;
  buttonUrl: string;
  segmentId: string;
};

export type IBroadcastButton = IBroadcast & {
  fileLink: string;
  fileType: string;
  broadcastText: string;
  buttonUrl: string;
  buttonPrompt: string;
};

export type IRecipient = {
  contactId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  read24h: boolean;
  read7d: boolean;
  clicked: boolean;
  revenue: number;
};

export enum BroadcastStatus {
  DRAFT = "0",
  SCHEDULED = "1",
  SENDING = "2",
  SENT = "3",
  CANCELLED = "4",
  ONGOING = "5",
}

export type ICampaignFormContext = {
  campaignFormDetails: {} | IBroadcast;
  setCampaignFormDetails: React.Dispatch<React.SetStateAction<object>>;
};
