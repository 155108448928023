import { createContext, useState } from "react";

import useGetPage from "../apis/hooks/use-get-page";
import useGetPagePricingPlanCompliance from "../apis/hooks/use-get-page-pricing-compliance";

import { IPageContext } from "../types/page.type";

const PageContext = createContext({} as IPageContext);

export const PageProvider = ({ children }: { children: React.ReactNode }) => {
  const [pageId, setPageId] = useState(
    localStorage.getItem("defaultPage") || ""
  );
  const { pageDetailsIsLoading, pageData } = useGetPage({
    pageId,
  });

  const { pageComplianceIsLoading, pageComplianceData } =
    useGetPagePricingPlanCompliance({ pageId });

  const pageIsLoading = pageDetailsIsLoading || pageComplianceIsLoading;

  return (
    <PageContext.Provider
      value={{
        pageId,
        setPageId,
        pageIsLoading,
        fbPage: pageData && pageData.data.page,
        pageCompliance: pageComplianceData && pageComplianceData.data,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContext;
