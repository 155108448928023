import Box, { BoxProps } from "@mui/material/Box";

import { useResponsive } from "../../hooks";

import { NAV, HEADER } from "../config-layout";

// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({ children, sx, ...other }: BoxProps) {
  const lgUp = useResponsive("up", "lg");

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: "flex",
        flexDirection: "column",
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
        }),
        bgcolor: "#f5f9fc",
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
