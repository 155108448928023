import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useRouter } from "../../hooks";
import { updateBroadcast } from "../apiBroadcast";

const useUpdateCampaign = () => {
  const router = useRouter();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateBroadcast,
    onSuccess: async (data, variables) => {
      const { broadcastId, formData } = variables;
      const { pageId } = formData;

      await queryClient.invalidateQueries({
        queryKey: ["broadcast", pageId, broadcastId],
      });

      router.push(`/app/campaign/${broadcastId}`);
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

export default useUpdateCampaign;
