import { useQuery } from "@tanstack/react-query";

import { getPagePricingPlanCompliance } from "../apiPage";

const useGetPagePricingPlanCompliance = ({ pageId }: { pageId: string }) => {
  const {
    isLoading: pageComplianceIsLoading,
    data: { data: pageComplianceData } = {},
    error,
  } = useQuery({
    queryKey: ["page", pageId, "pricingPlanCompliance"],
    queryFn: () => getPagePricingPlanCompliance(pageId),
    retry: false,
  });

  return { pageComplianceIsLoading, pageComplianceData, error };
};

export default useGetPagePricingPlanCompliance;
