import { useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import { useParams } from "react-router-dom";

import Iconify from "../components/iconify";
import FormProvider, { RHFTextField } from "../components/hook-form";
import { useRouter, useBoolean } from "../hooks";

import Alert from "@mui/material/Alert";
import { resetPassword } from "../helpers/apiAuth";

import { useSnackbar } from "../components/snackbar";

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const router = useRouter();
  const params = useParams();
  const password = useBoolean();

  const token = params.token || "";

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please fill all fields ")
      .min(8, "Password must be at least 8 characters"),
  });

  const defaultValues = {
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const password = data.password;

    try {
      const res = await resetPassword({ token, password });
      const { status } = res;

      if (status === "success") {
        enqueueSnackbar("Password reset successful");

        router.push("/");
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
  });

  const renderForm = (
    <Stack spacing={2.5}>
      <Typography variant="h4">Password reset</Typography>
      <RHFTextField
        name="password"
        label="Password (min. length 8)"
        type={password.value ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify
                  icon={
                    password.value ? "solar:eye-bold" : "solar:eye-closed-bold"
                  }
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        sx={{ justifyContent: "space-between", pl: 2, pr: 1.5 }}
      >
        Reset my password
      </LoadingButton>

      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
    </Stack>
  );

  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>
      <DevTool control={control} />
    </>
  );
}
