import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getContact, changeSubscription } from "../helpers/apiContact";

import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import { Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import Spinner from "../components/Spinner";
import CustomBreadcrumbs from "../components/custom-breadcrumbs";
import Label from "../components/label";

import { Typography } from "@mui/material";

import PageContext from "../context/pageContext";
import { IPageContext } from "../types/page.type";

type StatusProp = {
  statusText: string;
  statusColor:
    | "info"
    | "success"
    | "error"
    | "warning"
    | "default"
    | "primary"
    | "secondary";
};

export default function Contact() {
  const [currentClock, setCurrentClock] = useState("");
  const [status, setStatus] = useState({} as StatusProp);
  const [suppresionStatus, setSuppressionStatus] = useState("");
  const { pageId } = useContext(PageContext) as IPageContext;

  const params = useParams();
  const contactId = params.contactId || "";
  const { isLoading, data } = useQuery({
    queryKey: ["contact", pageId, contactId],
    queryFn: () => getContact({ pageId, contactId }),
    retry: 1,
  });

  const contact = data?.data?.contacts[0];

  const {
    contactFullName,
    contactProfilePic,
    contactGender,
    contactLocale,
    contactTimezone,
    subscriptionStatus,
    lastInteraction,
    // subscribeRef = null,
  } = contact || {};

  useEffect(() => {
    setSuppressionStatus(subscriptionStatus);
    statusConvertFull(subscriptionStatus);
  }, [subscriptionStatus]);

  const timezoneOffset = Number(contactTimezone);

  useEffect(() => {
    if (!isNaN(timezoneOffset)) {
      setInterval(() => {
        const date = new Date();
        const utcDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60000
        );
        const desiredDate = new Date(
          utcDate.getTime() + timezoneOffset * 3600000
        );
        const hours = desiredDate.getHours().toString().padStart(2, "0");
        const minutes = desiredDate.getMinutes().toString().padStart(2, "0");
        const seconds = desiredDate.getSeconds().toString().padStart(2, "0");
        setCurrentClock(`${hours}:${minutes}:${seconds}`);
      }, 1000);
    } else {
      setCurrentClock(" ");
    }
  }, [contactTimezone, timezoneOffset]);

  const toggleSuppress = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (suppresionStatus === "1") {
      statusConvertFull("3");
      setSuppressionStatus("3");
    } else if (suppresionStatus === "3") {
      statusConvertFull("1");
      setSuppressionStatus("1");
    }

    await changeSubscription({ pageId, contactId });
  };

  const statusConvertFull = (status: String) => {
    switch (status) {
      case "0":
        setStatus({
          statusText: "Never subscribed",
          statusColor: "info",
        });
        break;
      case "1":
        setStatus({
          statusText: "Subscribed",
          statusColor: "success",
        });
        break;
      case "2":
        setStatus({
          statusText: "Unsubscribed",
          statusColor: "error",
        });
        break;
      case "3":
        setStatus({
          statusText: "Suppressed",
          statusColor: "warning",
        });
        break;
      default:
        setStatus({
          statusText: "Unknown",
          statusColor: "info",
        });
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          direction="column"
          alignItems="left"
          sx={{
            mb: { xs: 3, md: 5 },
            padding: "1rem",
          }}
        >
          <CustomBreadcrumbs
            heading="Subscribers"
            links={[
              {
                name: "Home",
                href: "/",
              },
              {
                name: "Subscribers",
                href: "/app/subscribers",
              },
              {
                name: contactFullName,
              },
            ]}
          />

          <Card sx={{ mt: 0 }}>
            <Avatar /* makes profile pic circular */
              src={contactProfilePic || "../../default-profile.png"}
              alt={contactFullName}
              sx={{ width: 140, height: 140, margin: "auto", mt: 2 }}
            />

            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                align="center"
              >
                {contactFullName}
              </Typography>

              <Typography variant="h6" component="div">
                Details
              </Typography>

              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="body2" color="textSecondary">
                    Status:&nbsp;
                  </Typography>
                  <Label
                    variant="soft"
                    sx={{ maxWidth: 100 }}
                    color={status.statusColor}
                  >
                    {status.statusText}
                  </Label>
                  {(suppresionStatus === "1" || suppresionStatus === "3") && (
                    <Button variant="text" onClick={toggleSuppress}>
                      {suppresionStatus === "3" ? "Unsuppress" : "Suppress"}
                    </Button>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="body2" color="textSecondary">
                    Gender:&nbsp;
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {contactGender}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="body2" color="textSecondary">
                    Locale:&nbsp;
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {contactLocale}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="body2" color="textSecondary">
                    Local time:&nbsp;
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {currentClock}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6" component="div">
                    Activity
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                  >
                    Last Interaction:&nbsp;
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    component="span"
                  >
                    {lastInteraction
                      ? `${Math.floor(
                          (new Date().getTime() -
                            new Date(lastInteraction).getTime()) /
                            (1000 * 60 * 60 * 24)
                        )} day(s) ago`
                      : " -"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Stack>
      </Container>
    </>
  );
}
