import { Outlet } from "react-router-dom";
import Stack from "@mui/material/Stack";

import Logo from "../components/LogoNew";
// ----------------------------------------------------------------------

export default function AuthModernCompactLayout() {
  const renderLogo = (
    <Logo
      disabledLink
      sx={{
        justifyContent: "center",
        width: 800,
        height: 60,
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: "auto",
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        mt: 2,
      }}
    >
      <Outlet />
    </Stack>
  );

  return (
    <>
      <Stack
        component="main"
        direction="column"
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {renderLogo}
        {renderContent}
      </Stack>
    </>
  );
}
