import { Container, Stack } from "@mui/material";

import CustomBreadcrumbs from "../components/custom-breadcrumbs";
import CampaignCreateEditForm from "../sections/broadcast/campaign-create-edit-form";

// ----------------------------------------------------------------------

export default function CampaignCreate() {
  return (
    <>
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          direction="column"
          alignItems="flex-start"
          sx={{
            mb: { xs: 3, md: 5 },
            padding: "1rem",
          }}
        >
          <CustomBreadcrumbs heading="Home" links={[]} />
          <CampaignCreateEditForm />
        </Stack>
      </Container>
    </>
  );
}
