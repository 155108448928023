const getContacts = async ({
  pageId,
  page,
  PAGE_SIZE,
  sortField = "contactFullName",
}: {
  pageId: string;
  page: number;
  PAGE_SIZE: number;
  sortField?: string;
}) => {
  const response = await fetch(
    `/api/contact/${pageId}?page=${page}&limit=${PAGE_SIZE}&sort=${sortField}`
  );
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const getContact = async ({
  pageId,
  contactId,
}: {
  pageId: string;
  contactId: string;
}) => {
  const response = await fetch(`/api/contact/${pageId}?contactId=${contactId}`);
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const changeSubscription = async ({
  pageId,
  contactId,
}: {
  pageId: string;
  contactId: string;
}) => {
  const res = await fetch(`/api/contact/suppress/${pageId}/${contactId}`, {
    method: "PUT",
  });
  const data = await res.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

export { getContacts, getContact, changeSubscription };
