import * as Yup from "yup";
import { useMemo, useContext } from "react";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";

import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

import { useRouter } from "../hooks";

import FormProvider, { RHFTextField } from "../components/hook-form";

import SegmentDefinition from "../sections/segment/SegmentDefinitionNew";
import { useSnackbar } from "../components/snackbar";
import CustomBreadcrumbs from "../components/custom-breadcrumbs";

import PageContext from "../context/pageContext";

import { createSegment } from "../helpers/apiSegment";

import { IPageContext } from "../types/page.type";

// ----------------------------------------------------------------------

type ISegmentItem = {
  id: string;
  subscriberAction: string;
  subscriberTimeframe: string;
  subscriberTime: number;
};

type ISegment = {
  segmentId: string;
  pageId: string;
  segmentName: string;
  isList: boolean;
  items: ISegmentItem[];
};

type Props = {
  currentSegment?: ISegment;
};

export default function SegmentCreate({ currentSegment }: Props) {
  const { pageId } = useContext(PageContext) as IPageContext;

  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const NewSegmentSchema = Yup.object().shape({
    segmentName: Yup.string().required("Segment Name is required"),
    items: Yup.lazy(() =>
      Yup.array().of(
        Yup.object({
          subscriberAction: Yup.string().required("Required"),
          subscriberTimeframe: Yup.string().required("Required"),
          subscriberTime: Yup.number()
            .required("Required")
            .min(1, "Must be more than 0"),
        })
      )
    ),
    // not required
  });

  const defaultValues = useMemo(
    () => ({
      segmentName: "",
      items: currentSegment?.items || [
        {
          subscriberAction: "",
          subscriberTimeframe: "",
          subscriberTime: 0,
        },
      ],
    }),
    [currentSegment]
  );

  const methods = useForm({
    resolver: yupResolver(NewSegmentSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const handleCreate = handleSubmit(async (data) => {
    try {
      const { segmentName, items } = data;

      const queryString = items?.map((item) => [
        item.subscriberAction,
        item.subscriberTimeframe,
        item.subscriberTime.toString(),
      ]);

      // Original version contactCount: segmentData?.contactCount ?? 0,
      const body = {
        pageId,
        segmentName,
        isList: false,
        contactCount: 0,
        queryString,
      };

      const { status } = await createSegment(body);
      if (status === "success") {
        enqueueSnackbar("Segment created successfully");
        router.push("/app/segment");
      }
    } catch (error) {
      console.error(error);
    }
  });

  const renderNameInput = (
    <Stack spacing={3} sx={{ p: 3, pb: 0 }}>
      <Typography variant="subtitle2">Segment Builder</Typography>
      <RHFTextField
        name="segmentName"
        label="Segment Name *"
        InputLabelProps={{ shrink: true }}
      />
    </Stack>
  );

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          direction="column"
          alignItems="left"
          sx={{
            mb: { xs: 3, md: 5 },
            padding: "1rem",
          }}
        >
          <CustomBreadcrumbs
            heading="Segments"
            links={[
              {
                name: "Home",
                href: "/",
              },
              {
                name: "Segments",
                href: "/app/segment",
              },
              {
                name: "New",
              },
            ]}
          />
          <FormProvider methods={methods}>
            <Card>
              {renderNameInput}
              <SegmentDefinition />
            </Card>

            <Stack
              justifyContent="flex-end"
              direction="row"
              spacing={2}
              sx={{ mt: 3 }}
            >
              <LoadingButton
                size="large"
                variant="contained"
                loading={isSubmitting}
                onClick={handleCreate}
              >
                {currentSegment ? "Update" : "Create"} Segment
              </LoadingButton>
            </Stack>
            <DevTool control={control} />
          </FormProvider>
        </Stack>
      </Container>
    </>
  );
}
