import { useState, useEffect } from "react";
import {
  MenuItem,
  TableRow,
  TableCell,
  IconButton,
  Avatar,
  Link,
} from "@mui/material";

import { RouterLink } from "../../helpers/router-link";
import Iconify from "../../components/iconify";
import CustomPopover, { usePopover } from "../../components/custom-popover";
import Label from "../../components/label";

import { ISubscriber } from "../../types/subscriber.type";
// ----------------------------------------------------------------------

type Props = {
  row: ISubscriber;
  onViewRow: VoidFunction;
  onToggle: VoidFunction;
};

type StatusProp = {
  statusText: string;
  statusColor:
    | "info"
    | "success"
    | "error"
    | "warning"
    | "default"
    | "primary"
    | "secondary";
};

export default function SubscriberTableRow({
  row,
  onViewRow,
  onToggle,
}: Props) {
  const {
    contactId,
    contactFullName,
    contactGender,
    contactProfilePic,
    subscriptionStatus,
    lastInteraction,
  } = row;
  const popover = usePopover();

  const [status, setStatus] = useState({} as StatusProp);

  useEffect(() => {
    statusConvertFull(subscriptionStatus);
  }, [subscriptionStatus]);

  const statusConvertFull = (status: String) => {
    switch (status) {
      case "0":
        setStatus({
          statusText: "Never subscribed",
          statusColor: "info",
        });
        break;
      case "1":
        setStatus({
          statusText: "Subscribed",
          statusColor: "success",
        });
        break;
      case "2":
        setStatus({
          statusText: "Unsubscribed",
          statusColor: "error",
        });
        break;
      case "3":
        setStatus({
          statusText: "Suppressed",
          statusColor: "warning",
        });
        break;
      default:
        setStatus({
          statusText: "Unknown",
          statusColor: "info",
        });
    }
  };

  return (
    <>
      <TableRow sx={{ fontWeight: 300 }} hover>
        <TableCell
          sx={{
            width: 250,
            display: "flex", // Apply flex display directly to TableCell
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              width: 40,
              height: 40,
              marginRight: 2,
            }}
          >
            {contactProfilePic && <img src={contactProfilePic} alt="" />}
          </Avatar>
          <Link
            component={RouterLink}
            href={`/app/subscribers/${contactId}`}
            sx={(theme) => ({
              color: theme.palette.grey[900],
            })}
          >
            {contactFullName}
          </Link>
        </TableCell>

        <TableCell>{contactGender}</TableCell>

        <TableCell>
          {lastInteraction
            ? `${Math.floor(
                (new Date().getTime() - new Date(lastInteraction).getTime()) /
                  (1000 * 60 * 60 * 24)
              )} day(s) ago`
            : "-"}
        </TableCell>

        <TableCell>
          <Label
            variant="soft"
            sx={{ maxWidth: 100 }}
            color={status.statusColor}
          >
            {status.statusText}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1 }}>
          <IconButton
            color={popover.open ? "inherit" : "default"}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            onViewRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (subscriptionStatus === "3" || subscriptionStatus === "1") {
              onToggle();
              popover.onClose();
            }
          }}
          disabled={subscriptionStatus !== "3" && subscriptionStatus !== "1"}
        >
          <Iconify icon="ion:toggle" />
          {subscriptionStatus === "3" ? "Unsuppress" : "Suppress"}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
