import { Container, Stack, Tabs, Tab } from "@mui/material";

import CustomBreadcrumbs from "../components/custom-breadcrumbs";

import SettingsAccount from "../sections/settings/settings-account";
import SettingsBilling from "../sections/settings/settings-billing";
import SettingsIntegration from "../sections/settings/settings-integration";

import { RouterLink } from "../helpers/router-link";

// ----------------------------------------------------------------------

export default function SettingsNew() {
  const currentTab = window.location.href.split("/").pop();

  const TABS = [
    {
      label: "Account",
      href: `/app/settings/account`,
      component: SettingsAccount,
    },
    {
      label: "Billing",
      href: `/app/settings/billing`,
      component: SettingsBilling,
    },
    {
      label: "Integration",
      href: `/app/settings/integration`,
      component: SettingsIntegration,
    },
  ];

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          direction="column"
          alignItems="left"
          sx={{
            mb: { xs: 3, md: 5 },
            padding: "1rem",
          }}
        >
          <CustomBreadcrumbs
            heading="Settings"
            links={[
              {
                name: "Home",
                href: "/",
              },
              {
                name: "Settings",
              },
            ]}
          />

          <Tabs value={currentTab}>
            {TABS.map((tab) => (
              <Tab
                key={tab.label}
                component={RouterLink}
                href={tab.href}
                label={tab.label}
                value={tab.label.toLowerCase()}
              />
            ))}
          </Tabs>

          {TABS.map((tab) => {
            if (tab.label.toLowerCase() === currentTab) {
              return <tab.component key={tab.label} />;
            }
            return null;
          })}
        </Stack>
      </Container>
    </>
  );
}
