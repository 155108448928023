import { useEffect, useCallback } from "react";
import { useRouter, useSearchParams, useAuthContext } from "../hooks";
import { useQuery } from "@tanstack/react-query";

import { getPageByShopifyUrl } from "../apis/apiPage";

import { SplashScreen } from "../components/loading-screen";

import { AuthFlow } from "../types/auth.type";
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: Props) {
  const { loading } = useAuthContext();

  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const router = useRouter();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get("returnTo") || "/app/home";
  const shopifyUrl = searchParams.get("shopifyUrl") || "";
  const pathname = window.location.pathname;

  const { authenticated, authFlow } = useAuthContext();

  const { data } = useQuery({
    queryKey: ["shopifyUrl", shopifyUrl],
    queryFn: () => getPageByShopifyUrl({ shopifyUrl }),
  });

  const check = useCallback(() => {
    //Prevent endless loop between auth & guest guard for not connectedPage
    if (pathname !== "/connect" && authenticated) {
      if (authFlow === AuthFlow.SHOPIFY) {
        if (data?.status === "fail") router.push("/shopify-welcome");
        if (data?.status === "success") {
          //TODO - defaultPage localStorage does not get refreshed
          localStorage.setItem("defaultPage", data?.data.page.pageId);
          router.replace(returnTo);
        }
      } else {
        router.replace(returnTo);
      }
    }
  }, [authenticated, returnTo, router, pathname, data, authFlow]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
